import { Routes } from '@angular/router';
import { ContraventionDetailsGuard } from './shared/guards/contravention-details.guard';

export const routes: Routes = [
  {
    path: 'checkout',
    loadComponent: () => import('./payment/pages/payment-checkout.component'),
    pathMatch: 'full',
  },
  {
    path: 'finished',
    loadComponent: () => import('./payment/pages/payment-done.component'),
    pathMatch: 'full',
  },
  {
    path: ':network/contravention-details',
    loadComponent: () => import('./contravention/pages/contravention-details/contravention-details.component'),
    canActivate: [ContraventionDetailsGuard],
    pathMatch: 'full',
  },
  {
    path: ':network',
    loadComponent: () => import('./contravention/pages/contravention-check/contravention-check.component'),
    pathMatch: 'full',
  },
  {
    path: '',
    loadComponent: () => import('./contravention/pages/contravention-check/contravention-check.component'),
    pathMatch: 'full',
  },
  {
    path: '**',
    loadComponent: () => import('./contravention/pages/contravention-check/contravention-check.component'),
  },
];
