import { Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutComponent } from './layout/layout/layout.component';
import { LanguageService } from './shared/services/language.service';
import { PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [LayoutComponent, ToastModule],
})
export class AppComponent implements OnInit {
  private translateService = inject(TranslateService);
  private languageService = inject(LanguageService);
  private primeNgConfig = inject(PrimeNGConfig);

  ngOnInit() {
    this.translateService.setDefaultLang('fr');
    const storageLang = this.languageService.getCurrentInStorage();
    this.languageService.setCurrent(storageLang ? storageLang : 'fr');
    this.updatePrimeNgConfigOnLangChange();
  }

  private updatePrimeNgConfigOnLangChange() {
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('primeng').subscribe(res => {
        this.primeNgConfig.setTranslation(res);
      });
    });
  }
}
