export const languages = {
  fr: {
    name: 'Français',
    flag: 'fr',
  },
  en: {
    name: 'English',
    flag: 'gb',
  },
};
