import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <div class="text-color-secondary">
      <p>Powered by ©<a href="https://datategy.net/" style="color: inherit" target="_blank">Datategy</a></p>
    </div>
  `,
  standalone: true,
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
