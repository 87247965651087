<div class="main-container">
  <header>
    <app-header />
  </header>
  <main>
    <router-outlet />
    <app-footer-legal-notice />
  </main>
  <footer class="footer">
    <app-footer />
  </footer>
</div>
