<div class="mt-4">
  @if (selectedNetwork) {
    <div class="flex justify-content-between flex-wrap">
      <div class="flex justify-content-start gap-5">
        <a [href]="selectedNetwork?.payment_network_logo_url" target="_blank">
          <img [src]="networkLogo" alt="logo-payment-network" class="img-logo-network" />
        </a>
        <p class="mt-3 text-color-secondary">{{ 'common.for' | translate }}</p>
        <a [href]="selectedNetwork?.payment_authority_logo_url" class="mr-4" target="_blank">
          <img [src]="authorityLogo" alt="logo-payment-authority" class="img-logo-authority" />
        </a>
      </div>
      <p-dropdown
        (onChange)="changeLanguage($event.value)"
        [(ngModel)]="currentLanguage"
        [options]="availableLanguages">
        <ng-template pTemplate="selectedItem">
          @if (currentLanguage) {
            <div>
              <img [src]="'/assets/flags/' + languages[currentLanguage].flag + '.png'" alt="" />
            </div>
          }
        </ng-template>
        <ng-template let-language pTemplate="item">
          <div class="flex align-content-center gap-3">
            <img [src]="'/assets/flags/' + languages[language].flag + '.png'" alt="" />
            <span>{{ languages[language].name }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  }
</div>
