import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-footer-legal-notice',
  standalone: true,
  template: `
    <div class="mt-5">
      <span class="footer">{{ 'footer.legal_notice' | translate }}</span>
    </div>
  `,
  imports: [TranslateModule],
})
export class FooterLegalNoticeComponent {}
