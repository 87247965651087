import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';

export const availableLanguages = ['fr', 'en'];

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
  ) {}

  getCurrent() {
    return this.translateService.currentLang;
  }

  setCurrentInStorage(language: string) {
    this.storageService.save(this.getStorageKey(), language);
  }

  getCurrentInStorage(): string {
    return this.storageService.get(this.getStorageKey());
  }

  setCurrent(language: string) {
    this.translateService.use(language);
    this.setCurrentInStorage(language);
  }

  private getStorageKey() {
    return 'language';
  }
}
