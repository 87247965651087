import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of, Subject } from 'rxjs';
import { ContraventionStoreService } from '../services/contravention-store.service';
import { NetworkParamsStoreService } from '../services/network-params-store.service';
import { NetworkParamsHandler } from '../utils/network-params-handler';
import { map, takeUntil } from 'rxjs/operators';

export const ContraventionDetailsGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const contraventionStoreService = inject(ContraventionStoreService);
  const networkParamsStoreService = inject(NetworkParamsStoreService);
  const router = inject(Router);
  const network = route.paramMap.get('network')?.toUpperCase();
  const destroy$: Subject<boolean> = new Subject<boolean>();
  const contravention = contraventionStoreService.getContravention();

  return networkParamsStoreService.networks$.pipe(
    takeUntil(destroy$),
    map((networkParamsHandler: NetworkParamsHandler) => {
      if (networkParamsHandler.isEmpty()) {
        router.navigateByUrl('');
        return of(false);
      }
      if (!networkParamsHandler.isNetworkExists(network)) {
        router.navigateByUrl('');
        return of(false);
      }
      if (!contravention) {
        router.navigateByUrl('');
        return of(false);
      }
      return of(true);
    }),
  );
};
