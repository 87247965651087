import { MessageService } from 'primeng/api';
import { NotificationService } from './notification.service';
import { inject } from '@angular/core';

export class NotificationToastrService extends NotificationService {
  private readonly toastrService = inject(MessageService);

  success(message: string, title?: string): void {
    this.toastrService.add({ severity: 'success', summary: title, detail: message });
  }

  error(message: string, title?: string): void {
    this.toastrService.add({ severity: 'error', summary: title, detail: message });
  }

  info(message: string, title?: string): void {
    this.toastrService.add({ severity: 'info', summary: title, detail: message });
  }
}
