import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { availableLanguages, LanguageService } from '../../shared/services/language.service';
import { languages } from '../../shared/constants/languages';
import { NetworkParams } from '../../shared/models/network-params.model';
import { NetworkParamsStoreService } from '../../shared/services/network-params-store.service';
import { ConfigService } from '../../shared/services/config.service';
import { Networks } from '../../shared/enums/networks';
import { NetworkParamsHandler } from '../../shared/utils/network-params-handler';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [DropdownModule, FormsModule, TranslateModule],
})
export class HeaderComponent implements OnInit {
  currentLanguage: string;
  availableLanguages = availableLanguages;
  languages = languages;
  defaultNetwork: NetworkParams;
  selectedNetwork: NetworkParams;
  url: string;
  networkParamsHandler: NetworkParamsHandler;

  constructor(
    private languageService: LanguageService,
    private networkParamsService: NetworkParamsStoreService,
    private configService: ConfigService,
  ) {}

  get authorityLogo(): string {
    return `${this.url}/${this.selectedNetwork?.payment_authority_logo}`;
  }

  get networkLogo(): string {
    return `${this.url}/${this.selectedNetwork?.payment_network_logo}`;
  }

  ngOnInit() {
    this.currentLanguage = this.languageService.getCurrent();
    this.initValue();
    this.getSelectedNetwork();
  }

  changeLanguage(code: string) {
    this.currentLanguage = code;
    this.languageService.setCurrent(code);
  }

  private getSelectedNetwork() {
    this.networkParamsService.selectedNetwork$.subscribe({
      next: (network: string) => {
        if (!network) {
          return;
        }
        const selectedNetwork = this.networkParamsHandler.getNetworkFromName(network);
        this.setSelectedNetwork(selectedNetwork);
      },
    });
  }

  private setSelectedNetwork(selectedNetwork: NetworkParams) {
    this.selectedNetwork = selectedNetwork ? selectedNetwork : this.defaultNetwork;
  }

  private getNetworks() {
    this.networkParamsService.networks$.subscribe({
      next: (networkParamsHandler: NetworkParamsHandler) => {
        if (networkParamsHandler.isEmpty()) {
          return;
        }
        this.networkParamsHandler = networkParamsHandler;
        this.defaultNetwork = networkParamsHandler.getNetworkFromName(Networks.CARS_LACROIX);
        this.setSelectedNetwork(this.selectedNetwork);
      },
    });
  }

  private initValue() {
    this.networkParamsService.setAvailableNetworks();
    this.getNetworks();
    this.url = this.configService.readConfig().apiUrl;
  }
}
